import { Provider } from 'react-redux';
import { Box, ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { theme } from './theme';

import { store } from '@/store';
import FeaturesLazy from '@/components/sections/features/featuresLazy.component';
import Plans from '@/components/sections/plans/plans.component';
import Hero from '@/components/sections/hero/hero.component';
import BannerLazy from '@/components/sections/banner/bannerLazy.component';
import Nav from '@/components/sections/nav/nav.component';
import DrawerComponent from '@/components/ui/drawer/drawer.component';
import Footer from '@/components/sections/footer/footer.component';
import heroBg from '@/assets/hero.webp';
import { initializeTagManager } from '@/utils/tagManager';
import { logPageView } from '@/utils/analytics';
import QuestionsLazy from '@/components/sections/questions/questionsLazy.component';

export const App = () => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const bg = `url(${heroBg})`;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false);
  const [showAnnual, setShowAnnual] = useState<boolean>(false);

  // eslint-disable-next-line react-func/max-lines-per-function
  useEffect(() => {
    logPageView();
    initializeTagManager();
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Box w="100%">
          <Nav menuRef={btnRef} onOpen={onOpen} />
          <Box
            bgSize={{ base: 'cover', xl: 'contain' }}
            bgImage={bg}
            bgPosition={'center'}
            bgRepeat={'no-repeat'}
            pb={'50px'}
          >
            <Hero />
          </Box>

          <Plans
            onPlanSelectionChange={setShowWhatsapp}
            onPeriodChange={setShowAnnual}
          />
          <FeaturesLazy showWhatsapp={showWhatsapp} showAnnual={showAnnual} />
          <BannerLazy />
          <QuestionsLazy />
          <Footer />
          <DrawerComponent isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
        </Box>
      </ChakraProvider>
    </Provider>
  );
};
