import { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

const Banner = lazy(() => import('./banner.component'));

export default function BannerLazy() {
  const { ref, inView } = useInView();

  return (
    <div ref={ref}>
      {inView ? (
        <Suspense fallback={null}>
          <Banner />
        </Suspense>
      ) : null}
    </div>
  );
}
