import { createIcon } from '@chakra-ui/react';

export const ArrowDown = createIcon({
  displayName: 'ArrowDown',
  viewBox: '0 0 23 27',
  path: (
    <>
      <path
        fill="currentColor"
        d="M22.75 3.019L20.106.375 11.5 8.963 2.894.375.25 3.019l11.25 11.25 11.25-11.25z"
      />
      <path
        fill="currentColor"
        d="M22.75 15.375l-2.644-2.644L11.5 21.32 2.894 12.73.25 15.375l11.25 11.25 11.25-11.25z"
      />
    </>
  ),
});
