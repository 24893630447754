import { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, Container, Text } from '@chakra-ui/react';

const Features = lazy(() => import('./features.components'));

import * as text from '@/i18n/es';
import { FeaturesProps } from '@/components/sections/features/features.types';

export default function FeaturesLazy({
  showWhatsapp,
  showAnnual,
}: FeaturesProps) {
  const { ref, inView } = useInView();

  return (
    <Container centerContent maxW={'12xl'} ref={ref}>
      <Box id={'features'} maxW={'8xl'}>
        <Box px={{ base: 3, md: 6 }} pb={3} textAlign={'left'}>
          <Text
            as="h2"
            fontWeight={'bold'}
            fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
            color={'gray'}
          >
            {text.default.details.title}
          </Text>
          <Text fontSize={{ base: 'md', sm: '18px', md: '20px' }}>
            {text.default.details.descriptions}
          </Text>
        </Box>
        <Box minHeight="600px">
          {inView && (
            <Suspense fallback={null}>
              <Features showWhatsapp={showWhatsapp} showAnnual={showAnnual} />
            </Suspense>
          )}
        </Box>
      </Box>
    </Container>
  );
}
