import styled from 'styled-components';

export const PlanCardStyled = styled.section`
  position: relative;

  .card {
    width: 290px;
    height: 500px;
    border-radius: 20px;

    &--sm {
      width: 195px;
      height: 195px;
      button {
        width: unset;
      }
    }

    &--corporate {
      button {
        color: #515151;
        background: white;
      }
    }

    &__price {
      font-size: 32px;
      font-weight: 700;
      padding: 20px 0;
    }

    &__attributes {
      list-style: none;

      .icon {
        font-size: 14px;
      }
      p {
        text-align: initial;
      }
    }

    &__action {
      margin-top: auto;
    }
  }
`;
