import { Tooltip } from 'cliengify';
import parse from 'html-react-parser';

import * as texts from '@/i18n/es';

type TextTooltipProps = {
  label: string;
};

const TextWithTooltip = ({ label }: TextTooltipProps) => {
  const textParts = label.split('#');
  const requiresTooltip = textParts.length > 1;
  const description = textParts[0];
  const text = texts.default;
  let tooltip = '';

  if (requiresTooltip) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    tooltip = eval(textParts[1].replace('#', ''));
  }

  return (
    <>
      {parse(description)}&nbsp;
      {requiresTooltip && <Tooltip fontSize={'sm'} label={tooltip}></Tooltip>}
    </>
  );
};

export default TextWithTooltip;
