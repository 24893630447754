import { IconName } from 'cliengify';

import * as text from '@/i18n/es';

export const plans = {
  allChannelPlans: [
    { icon: 'material-plant' as IconName, ...text.default.plans.starter },
    { icon: 'material-awesome' as IconName, ...text.default.plans.premium },
    { icon: 'material-work' as IconName, ...text.default.plans.business },
    { icon: 'icon-psychology' as IconName, ...text.default.plans.corporate },
  ],
  whatsappPlans: [
    { icon: 'material-awesome' as IconName, ...text.default.plans.whatsapp },
    { icon: 'material-work' as IconName, ...text.default.plans.business },
    { icon: 'icon-psychology' as IconName, ...text.default.plans.corporate },
  ],
};

export type Plan = {
  icon: IconName;
  title: string;
  description: string;
  descriptionSmall: string;
  buttonLabel?: string;
  buttonLabelSmall?: string;
  price: number | 0;
  priceAnnual: number | 0;
  currency?: string;
  attributes?: string[];
  isCorporate: boolean;
};
