import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Image,
  Button,
} from '@chakra-ui/react';
import React, { ReactNode, SyntheticEvent } from 'react';

import cliengoWhite from '@/assets/footer/cliengoWhite.webp';
import * as text from '@/i18n/es';
import { trackEvent } from '@/utils/analytics';
import { eventAction } from '@/utils/eventActions';
import { categoryEvent } from '@/utils/categoryEvents';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      fontWeight={'bold'}
      fontSize={'md'}
      pt={5}
      textTransform={'uppercase'}
    >
      {children}
    </Text>
  );
};

const SocialIcon = ({ url, icon }: { url: string; icon: string }) => {
  const goToCta = (event: SyntheticEvent) => {
    event.preventDefault();
    trackEvent(icon, categoryEvent.home);

    window.open(url, '_blank');
  };

  return (
    <Link
      href={url}
      onClick={goToCta}
      isExternal
      _hover={{ textDecoration: 'none' }}
    >
      <Button
        rounded={'full'}
        bg={'#b590ff'}
        variant={'primary'}
        px={0}
        aria-label={icon}
      >
        <i className={`icon-${icon}`}></i>
      </Button>
    </Link>
  );
};

export default function Footer() {
  const texts = text.default.footer;
  const startUrl = process.env.REACT_APP_START_LINK;
  const goToCta = (event: SyntheticEvent) => {
    event.preventDefault();
    trackEvent(eventAction.cta_footer, categoryEvent.home);

    setTimeout(() => {
      window.location.href = startUrl;
    }, 250);
  };

  return (
    <Box bg={'primary'} color={'white'}>
      <Container as={Stack} maxW={'6xl'} pt={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack align={'flex-start'} spacing={0}>
            <Image
              src={cliengoWhite as string}
              htmlWidth={'150px'}
              alt={'Cliengo logo'}
              pt={5}
            ></Image>
            <Text pt={3} fontSize={'sm'}>
              © {new Date().getFullYear()} cliengo.com
            </Text>
            <Text fontSize={'sm'}>Todos los derechos reservados.</Text>
            <Link
              href={startUrl}
              onClick={goToCta}
              _hover={{
                textDecoration: 'none',
              }}
              pt={2}
            >
              <Button
                width={'200px'}
                size={'md'}
                rounded={'full'}
                variant={'primary'}
                bg={'#7f44f8'}
                border={'1px solid white'}
                px={3}
              >
                {texts.cta}
              </Button>
            </Link>
          </Stack>
          <Stack align={'flex-start'} fontSize={'sm'} spacing={0}>
            <ListHeader>{texts.about}</ListHeader>
            <Link href={'https://www.cliengo.com/cliengo-que-es'} isExternal>
              {texts.cliengo}
            </Link>
            <Link href={'https://home.cliengo.com/terms'} isExternal>
              {texts.terms}
            </Link>
            <Link href={'https://home.cliengo.com/privacy'} isExternal>
              {texts.privacity}
            </Link>
            <Link
              href={'https://cliengo.zohorecruit.com/jobs/Careers'}
              isExternal
            >
              {texts.work}
            </Link>
          </Stack>
          <Stack align={'flex-start'} fontSize={'sm'} spacing={0}>
            <ListHeader>{texts.platform}</ListHeader>
            <Link href={'https://www.cliengo.com/chatbot'} isExternal>
              {texts.chatbotWeb}
            </Link>
            <Link href={'https://www.cliengo.com/chatbot-whatsapp'} isExternal>
              {texts.chatbotWhatsapp}
            </Link>
            <Link href={'https://www.cliengo.com/chatbot-facebook'} isExternal>
              {texts.chatbotFb}
            </Link>
            <Link href={'https://www.cliengo.com/chatbot-instagram'} isExternal>
              {texts.chatbotIg}
            </Link>
            <Link href={'https://www.cliengo.com/live-chat'} isExternal>
              {texts.live}
            </Link>
            <Link href={'https://www.cliengo.com/crm'} isExternal>
              {texts.crm}
            </Link>
          </Stack>
          <Stack align={'flex-start'} fontSize={'sm'} spacing={0}>
            <ListHeader>{texts.partners}</ListHeader>
            <Link href={'https://partners.cliengo.com/'} isExternal>
              {texts.bePartner}
            </Link>
            <ListHeader>{texts.resources}</ListHeader>
            <Link href={'https://blog.cliengo.com/'} isExternal>
              {texts.blog}
            </Link>
            <Link href={'https://help.cliengo.com/hc/es'} isExternal>
              {texts.help}
            </Link>
            <Link href={'https://developers.cliengo.com/docs'} isExternal>
              {texts.devs}
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Container centerContent>
        <Stack
          spacing={{ base: 5, md: 6 }}
          py={5}
          textAlign={'center'}
          align={'center'}
          flex={1}
          direction={'row'}
        >
          <SocialIcon
            url={'https://www.linkedin.com/company/cliengo/'}
            icon={'linkedin'}
          ></SocialIcon>
          <SocialIcon
            url={'https://www.facebook.com/cliengo/'}
            icon={'facebook'}
          ></SocialIcon>
          <SocialIcon
            url={'https://www.instagram.com/cliengo.chat/'}
            icon={'instagram'}
          ></SocialIcon>
          <SocialIcon
            url={'https://www.youtube.com/c/Cliengo?sub_confirmation=1'}
            icon={'youtube'}
          ></SocialIcon>
          <SocialIcon
            url={'https://twitter.com/cliengo'}
            icon={'twitter'}
          ></SocialIcon>
          <SocialIcon
            url={'mailto:hola@cliengo.com'}
            icon={'email'}
          ></SocialIcon>
        </Stack>
      </Container>
    </Box>
  );
}
