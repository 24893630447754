interface Dictionary {
  [key: string]: string | unknown;
}

interface AnalyticsJS {
  page: () => void;
  track: (eventName: string, properties: object) => void;
}

interface AnalyticsEvent {
  eventName: string;
  category: string;
  action: string;
  label?: string;
}

export const logPageView = (): void => {
  ((window as unknown as Dictionary).analytics as AnalyticsJS)?.page();
};

export const trackEvent = (
  action: string,
  category: string,
  label = ''
): void => {
  const event: AnalyticsEvent = {
    eventName: action,
    category,
    action,
  };

  if (label) {
    event.label = label;
  }

  ((window as unknown as Dictionary).analytics as AnalyticsJS)?.track(
    event.eventName,
    {
      ...event,
    }
  );
};
