const common = {
  agent: '<b>1 Agente incluido</b>#text.card.agent',
};

export default {
  nav: {
    start: 'Comenzar',
    login: 'Iniciar sesión',
  },
  hero: {
    title:
      'Conoce los precios de Cliengo y elige el plan que<br> se adapte a tus necesidades',
    description:
      'Comienza hoy <b>tu prueba gratuita por 14 días</b> y automatiza tus consultas y ventas en tus canales digitales.',
  },
  banner: {
    title: '¿Necesitas contratar más de un plan?',
    description:
      'Si eres una agencia de Marketing Digital o desarrollas plataformas de eCommerce para tus clientes, tenemos opciones especiales para ti. Conoce nuestro programa de Partners.',
    cta: 'Más información',
  },
  card: {
    month: 'Mes',
    recommended: 'Más elegido',
    agent: 'Personas de tu equipo con acceso a Cliengo.',
    paidAnnually: 'Pagando un año por adelantado',
  },
  plans: {
    monthly: 'Mensual',
    annual: 'Anual',
    converChannels: 'Todos los canales',
    comparePlans: '¿Tienes dudas? Compara nuestros planes',
    wpChannels: 'WhatsApp',
    ctaWhatsapp: 'Contáctanos',
    saveAnnually: 'Ahorra un 15% contratando un plan anual',
    starter: {
      title: 'Starter',
      description: 'Para empresas pequeñas con bajo volumen de consultas',
      descriptionSmall: 'Un plan básico y muy completo',
      price: 39,
      priceAnnual: 33,
      currency: 'USD',
      attributes: [
        common.agent,
        '250 Conversaciones',
        '3 Chatbots',
        '10 Preguntas en tu conversación',
        '<b>3 Canales de conexión</b>',
      ],
      buttonLabel: 'Prueba Cliengo gratis',
      buttonLabelSmall: 'Comenzar prueba',
      isCorporate: false,
    },
    premium: {
      title: 'Premium',
      description: 'Para empresas medianas con bajo volumen de consultas',
      descriptionSmall: 'Pensado para que no te falten herramientas',
      price: 89,
      priceAnnual: 76,
      currency: 'USD',
      attributes: [
        common.agent,
        '1000 Conversaciones',
        '10 Chatbots',
        '50 Preguntas en tu conversación',
        '<b>4 Canales de conexión</b>',
      ],
      buttonLabel: 'Prueba Cliengo gratis',
      buttonLabelSmall: 'Comenzar prueba',
      isCorporate: false,
    },
    business: {
      title: 'Business',
      description: 'Para empresas medianas con alto volumen de consultas',
      descriptionSmall: 'Ideal para tu empresa y tus colaboradores',
      price: 239,
      priceAnnual: 203,
      currency: 'USD',
      attributes: [
        common.agent,
        '4000 Conversaciones',
        '20 Chatbots',
        '100 Preguntas en tu conversación',
        '<b>Conexión total de canales</b>',
        '<b>Soporte prioritario</b>',
      ],
      buttonLabel: 'Prueba Cliengo gratis',
      buttonLabelSmall: 'Comenzar prueba',
      isCorporate: false,
    },
    corporate: {
      title: 'Corporate',
      description:
        'Para empresas grandes con múltiples canales de atención y consultas. Construiremos juntos la experiencia que más se adapte a sus objetivos de negocio.',
      descriptionSmall: 'Nos adaptamos a tu empresa y cumplimos tu objetivo',
      attributes: [
        '<span style="color:white;">Acceso total a la plataforma</span>',
        '<span style="color:white;">Chatbot personalizados</span>',
        '<span style="color:white;">Reportes personalizados</span>',
        '<span style="color:white;">Integraciones personalizadas</span>',
        '<span style="color:white;">Success Manager dedicado</span>',
      ],
      buttonLabel: 'Contáctanos',
      buttonLabelSmall: 'Contáctanos',
      isCorporate: true,
    },
    whatsapp: {
      title: 'Premium WhatsApp',
      description: 'Para empresas medianas con WhatsApp como canal principal',
      descriptionSmall: 'Pensando para que no te falten herramientas',
      price: 129,
      priceAnnual: 110,
      currency: 'USD',
      attributes: [
        common.agent,
        '1000 Conversaciones',
        '1 Chatbot de WhatsApp<br/>+ 10 otros canales',
        '50 Preguntas en tu conversación',
        '<b>Conexión total de canales</b>',
        '<b>WhatsApp Business API incluido</b>',
      ],
      buttonLabel: 'Contáctanos',
      buttonLabelSmall: 'Contáctanos',
    },
  },
  details: {
    title: 'Elije la opción ideal para tu empresa',
    descriptions:
      'Compara los planes y encuentra el que más se adapta a las necesidades de tu negocio. Si aún tienes dudas, recuerda que puedes probarlo por 14 días gratis sin ingresar tu tarjeta de crédito.',
    plans: {
      starter: {
        title: 'Starter',
        description: 'Un plan básico y muy completo',
        buttonLabel: 'Comenzar prueba',
      },
      premium: {
        title: 'Premium',
        description: 'Pensando para que no te falten herramientas',
        buttonLabel: 'Comenzar prueba',
      },
      business: {
        title: 'Business',
        description: 'Ideal para grandes empresas',
        buttonLabel: 'Comenzar prueba',
      },
      corporate: {
        title: 'Corporate',
        description: 'Nos adaptamos a los objetivos y desafíos de tu empresa',
        buttonLabel: 'Contáctanos',
      },
      whatsapp: {
        title: 'Premium WhatsApp',
        description: 'Pensando para que no te falten herramientas',
        buttonLabel: 'Comenzar prueba',
      },
    },
    accordion: {
      general: {
        title: 'General',
        attributes: [
          'Precio mensual',
          'Precio Anual',
          'Conversaciones incluidas',
          'Conversaciones adicionales',
          'Agente adicional mensual',
          'Agente adicional anual',
        ],
        tooltips: {
          converPack:
            'Pack de conversaciones que se activan en tu plan luego de consumir las que vienen incluidas.',
          additionalAgent:
            'Costo de un agente adicional cuando tu suscripción es mensual.',
          additionalAgentAnnual:
            'Costo de un agente adicional cuanto tu suscripción es anual.',
        },
      },
      channels: {
        title: 'Canales',
        attributes: [
          'Chatbot en Sitio Web',
          'Formulario de WhatsApp',
          'Chatbot en WhatsApp Business API',
          'WhatsApp HSM',
          'Chatbot en Facebook Messenger',
          'Chatbot en Instagram ',
          'Quitar marca de agua en los chatbots',
        ],
        tooltips: {
          wsappForm:
            'Formulario de WhatsApp te permite consultar los datos de contacto e interés del usuario antes de iniciar una conversación por este canal, para poder autoasignar al equipo o agente correspondiente.',
          wsappTool:
            'Herramienta que permite utilizar WhatsApp para la atención al cliente. Puedes conectarle un chatbot, e intervenir en vivo las conversaciones desde el Live.',
          available40:
            'Disponible en el plan por un costo adicional de 40$ USD/mes por linea.',
          hsm: 'Mensajes salientes de WhatsApp que pueden ser aplicados a diferentes casos de uso como: seguimiento de compra, confirmación de reserva, recordatorio de turno, etc.',
          available: 'Disponible en el plan por un costo adicional.',
          wsapp1Line:
            '1 Línea de WhatsApp incluida, 40$ USD/mes por linea adicional.',
        },
      },
      chatbot: {
        title: 'Chatbots',
        attributes: [
          'Horarios de atención',
          'Autoasignación a vendedores',
          'Mensajes por árbol de conversaciones',
          'Respuestas con opciones',
          'Etiquetas',
          'Respuestas sugeridas',
          'Chatbots a medida',
        ],
        tooltips: {
          available: 'Disponible en el plan por un costo adicional.',
          availableTwoAgents: 'Disponible a partir de dos agentes en tu cuenta',
          message: 'Cuantos mensaje se pueden configurar por chatbot',
          conversations600:
            'Con al menos un mínimo de 600 conversaciones por canal.',
          conversationTree:
            'Chatbot con un árbol de conversación personalizado de acuerdo a las necesidades de la empresa.',
        },
      },
      live: {
        title: 'Live Chat',
        attributes: [
          'Agentes incluidos',
          'Notifícaciones en escritorio',
          'Aplicación para dispositivos móviles',
          'Contexto del visitante',
          'Historial de conversaciones',
          'Asignar a agentes por reglas',
          'Notas',
          'Reactivación de conversaciones vía HSM',
        ],
        tooltips: {
          info: 'Información de navegación de los usuarios que conversan con tu empresa en los diferentes canales, tales como: fuente de procedencia, detalle de campañas, etc.',
        },
      },
      management: {
        title: 'Gestión',
        attributes: [
          'Emails de notificación',
          'Gestor de clientes integrado',
          'Agentes incluidos',
          'Agentes adicionales',
          'Contexto del cliente (UTMs, URLs)',
          'Emails de seguimiento automático',
          'Subestados en CRM',
          'Equipos de trabajo',
          'Visualización de reportes',
          'Reportes personalizados',
        ],
      },
      integrations: {
        title: 'Integraciones',
        attributes: [
          'Conversiones Google Ads',
          'Traqueo por Google Analytics',
          'Conversiones Offline Google Ads ',
          'Audiencias Google Ads',
          'Tokko Broker',
          'Tecnom',
          'Pilot CRM',
          'API Cliengo',
          'Webhooks Cliengo',
          'Plantilla preconfiguradas para integrar con Zapier',
          'Pixel conversión',
          'Integración Google Calendar',
          'Integración Google Sheets',
          'Integración Woocommerce',
          'Integración Shopify',
          'Integración de formularios',
          'Facebook Lead Ads',
          'Integraciones personalizadas por API',
          'Integraciones a medida',
        ],
        tooltips: {
          available: 'Disponible en el plan por un costo adicional.',
        },
      },
      support: {
        title: 'Soporte',
        attributes: [
          'Documentación de ayuda',
          'Velocidad de Atención',
          'Canales de atención internos',
          'Capacitaciones a tu equipo de trabajo',
          'Soporte personalizado con ejecutivo',
          'Soporte premium por WhatsApp',
          'Technical integration Set Up',
        ],
        tooltips: {
          priority:
            'Prioridad con la que nuestros asesores contestarán tu consulta de acuerdo al servicio propuesto en el plan elegido.',
        },
      },
    },
    general: {
      available: 'Disponible',
      addons: 'Adicional {price} uds/mes',
      fit: 'A medida',
      toAgree: 'A convenir',
      agent: 'Agente',
      currencyMonth: 'USD/Mes',
      standard: 'Estándar',
      priority: 'Prioritario',
      assigned: 'Asignado',
      basic: 'Básicos',
      advanced: 'Avanzados',
      custom: 'Personalizados',
      days: 'Días',
      hour: 'Hora',
      hours: 'Horas',
      mail: 'Email',
      chat: 'Chat',
      wsapp: 'WhatsApp',
      successManager: 'Success Manager',
      fromStart: 'Desde el inicio de la cuenta',
      lastDays: 'Últimos {days} días',
      historical: 'Histórico',
    },
  },
  questions: {
    title: 'Preguntas frecuentes',
    queries: [
      {
        label: '¿Necesito ingresar un medio de pago para registrarme?',
        answer:
          '¡No es necesario! Puedes comenzar con tu prueba gratis inmediatamente, sin ingresar ningún método de pago.',
      },
      {
        label: '¿Qué sucede después de la prueba gratis?',
        answer:
          'Luego de la prueba de 14 días podrás elegir, acorde al uso que le hayas dado a la herramienta, un plan que se amolde a tus necesidades.',
      },
      {
        label: '¿Mi suscripción se renueva cuando termina el periodo?',
        answer:
          'Si, cuando cumplas el plazo de tu suscripción (mensual/anual) se renovará automáticamente. Puedes cancelarla en cualquier momento.',
      },
      {
        label: '¿Cuáles son los medios de pago?',
        answer: 'Aceptamos tarjeta de débito y crédito.',
      },
      {
        label: '¿Cómo se contabilizan las conversaciones?',
        answer:
          'Todas las personas que mantengan una interacción con el chat son consideradas conversaciones.',
      },
      {
        label: '¿Qué sucede cuando llego al límite de conversaciones?',
        answer:
          'Automáticamente se habilitarán los packs de conversaciones extras con costo adicional para que puedas seguir interactuando con los usuarios que visitan el sitio. Las conversaciones extras se irán consumiendo hasta que se renueve tu periodo de facturación.',
      },
      {
        label: '¿El límite de conversaciones de cada plan, es por canal?',
        answer:
          'No, el límite de conversaciones se contabiliza por el total que recibas, sin importar en que canal se generen.',
      },
      {
        label: '¿Puedo cancelar la suscripción en cualquier momento?',
        answer:
          'Sí, puedes cancelar tu suscripción en cualquier momento desde tu cuenta si eres el administrador. Debes estar logueado e ingresar a Mi Plan > Facturación > Cancelar Suscripción. Si tienes un plan corporativo, comunícate con tu ejecutivo de cuentas.',
      },
    ],
  },
  footer: {
    cta: 'Comenzar',
    about: 'Nosotros',
    products: 'Productos',
    price: 'Precios',
    platform: 'Plataforma',
    partners: 'Partners',
    resources: 'Recursos',
    cliengo: 'Qué es Cliengo',
    terms: 'Términos y condiciones',
    privacity: 'Política de privacidad',
    work: 'Trabaja con nosotros',
    cases: 'Casos de clientes',
    chatbotWeb: 'Chatbot Web',
    chatbotWhatsapp: 'Chatbot WhatsApp',
    chatbotFb: 'Chatbot Facebook',
    chatbotIg: 'Chatbot Instagram',
    live: 'Live Chat',
    crm: 'CRM',
    bePartner: 'Conviértete en Partner',
    blog: 'Blog',
    help: 'Centro de Ayuda',
    devs: 'API para Desarrolladores',
  },
};
