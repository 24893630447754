import cn from 'classnames';
import { Box, Card, Link } from '@chakra-ui/react';
import { Button, Icon, Text, TextProps } from 'cliengify';
import React, { SyntheticEvent } from 'react';

import { RecommendedCard } from '../recommendedCard/recommendedCard.component';
import { RecommendedCardProps } from '../recommendedCard/recommendedCard.types';

import { PlanCardStyled } from './planCard.styles';
import { PlanCardProps } from './planCard.types';
import { variants } from './planCard.config';

import * as text from '@/i18n/es';
import { trackEvent } from '@/utils/analytics';
import { categoryEvent } from '@/utils/categoryEvents';
import { redirect } from '@/utils/redirect';
import TextWithTooltip from '@/components/ui/textWithTooltip/textWithTooltip.component';

export const PlanCard = ({
  isMonthly,
  size = 'lg',
  variant = 'default',
  isWhatsapp = false,
  plan,
  ...props
}: PlanCardProps) => {
  const startUrl = process.env.REACT_APP_START_LINK;
  const corporateUrl = process.env.REACT_APP_CORPORATE_LINK;
  const whatsappUrl = process.env.REACT_APP_WHATSAPP_LINK;
  const variantProps = variants[variant][size];
  const isCorporate = variant === 'corporate';
  const isSmall = size === 'sm';
  const {
    icon,
    title,
    description,
    descriptionSmall,
    buttonLabel,
    buttonLabelSmall,
    price,
    priceAnnual,
    currency,
    attributes,
  } = plan;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  let ctaUrl = `${startUrl}?utm_ref=${title}`;

  const planPrice = isMonthly ? price : priceAnnual;
  let ctaLabel = isSmall ? buttonLabelSmall : buttonLabel;

  if (isWhatsapp) {
    ctaUrl = whatsappUrl;
  }

  if (isCorporate) {
    ctaUrl = corporateUrl;
  }

  if (isWhatsapp && !isCorporate) {
    ctaLabel = text.default.plans.ctaWhatsapp;
  }
  const goToCta = (event: SyntheticEvent) => {
    let action = `cta_ft_${title.toLowerCase()}`;
    const titleParts = title.split(' ');

    // whatsapp contains two sections
    if (titleParts.length > 1) {
      action = `cta_ft_${titleParts.join('_').toLowerCase()}`;
    } else if (isWhatsapp) {
      action += '_whatsapp';
    }

    if (isSmall) {
      action += '_2';
    }

    event.preventDefault();
    trackEvent(action, categoryEvent.home);
    redirect(event.target as HTMLButtonElement);
  };

  return (
    <Box px={isSmall ? '5px' : '0px'}>
      <PlanCardStyled data-testid="plan-card">
        {/* Recommended Card */}
        {['recommended', 'wsapp'].includes(variant) && (
          <RecommendedCard
            {...(variantProps.recommended as RecommendedCardProps)}
            label={text.default.card.recommended}
            size={size}
          />
        )}

        <Card
          align="center"
          className={cn('card', {
            'card--corporate': isCorporate,
            'card--sm': isSmall,
          })}
          {...variantProps.card}
          {...props}
        >
          {/* Title */}
          <Text
            display="flex"
            alignItems="center"
            {...(variantProps.title as TextProps)}
          >
            <Icon
              name={icon}
              fontSize="30px"
              marginRight={isSmall ? '0' : '13px'}
              {...variantProps.titleIcon}
            />
            {title}
          </Text>
          {/* Description */}
          <Text
            as={'p'}
            minH={'45px'}
            fontSize={isSmall ? '12' : '16'}
            {...(variantProps?.description as TextProps)}
          >
            {isSmall ? descriptionSmall : description}
          </Text>

          {/* Price */}
          {!isSmall && currency && (
            <section className="card__price">
              <Text
                as={'p'}
                fontSize={'30px'}
                color="info"
                fontWeight={'bold'}
              >{`$${planPrice} ${currency} / ${text.default.card.month}`}</Text>
              {!isMonthly && (
                <Text fontSize="12px" marginTop="-5px" fontWeight="semibold">
                  ({text.default.card.paidAnnually})
                </Text>
              )}
            </section>
          )}

          {/* Attributes List */}
          {!isSmall && attributes && (
            <ul className="card__attributes">
              {attributes.map((attr, index) => (
                <li key={index}>
                  <Text
                    leftIcon="icon-done"
                    fontSize="14"
                    pt={'5px'}
                    marginBottom="5px"
                    {...(variantProps.attributes as TextProps)}
                  >
                    <TextWithTooltip label={attr}></TextWithTooltip>
                  </Text>
                </li>
              ))}
            </ul>
          )}

          {/* Action */}
          <section className="card__action">
            <Link href={ctaUrl} onClick={goToCta}>
              <Button
                data-url={ctaUrl}
                variant="primary"
                size={isSmall ? 'sm' : 'lg'}
                fontWeight={'bold'}
                name={plan.title}
              >
                {ctaLabel}
              </Button>
            </Link>
          </section>
        </Card>
      </PlanCardStyled>
    </Box>
  );
};
