// TODO: simplificar
export const variants = {
  default: {
    lg: {
      recommended: {
        variant: 'default',
      },
      card: {
        border: 'none',
        bg: 'white',
        shadow: '0px 2px 9px rgba(125, 96, 245, 0.6)',
        p: '20px',
      },
      title: {
        variant: 'h2',
        color: 'primary',
        iconColor: 'primary',
      },
      titleIcon: {
        w: '30px',
        h: '30px',
      },
      description: {
        textAlign: 'center',
        pb: 0,
      },
      attributes: {
        iconColor: 'primary',
      },
    },
    sm: {
      recommended: {
        variant: 'default',
      },
      card: {
        border: 'none',
        bg: 'white',
        shadow: '0px 2px 9px rgba(125, 96, 245, 0.6)',
        p: '20px',
      },
      title: {
        variant: 'p',
        fontSize: '22px',
        flexDirection: 'column',
        color: 'primary',
        iconColor: 'primary',
      },
      titleIcon: {
        w: '27px',
        h: '27px',
      },
      description: {
        variant: 'helper',
        fontSize: '13px',
        textAlign: 'center',
        pb: 0,
      },
      attributes: {},
    },
  },
  recommended: {
    lg: {
      recommended: {
        variant: 'default',
      },
      card: {
        border: '2px solid transparent',
        bg: 'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #7d60f5, #c059ff) border-box',
        shadow: '0px 2px 9px rgba(125, 96, 245, 0.6)',
        p: '20px',
      },
      title: {
        variant: 'h2',
        color: 'primary',
        iconColor: 'primary',
      },
      titleIcon: {
        w: '30px',
        h: '30px',
      },
      attributes: {
        iconColor: 'primary',
      },
    },
    sm: {
      recommended: {
        variant: 'default',
      },
      card: {
        border: '2px solid transparent',
        bg: 'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #7d60f5, #c059ff) border-box',
        shadow: '0px 2px 9px rgba(125, 96, 245, 0.6)',
        p: '20px',
      },
      title: {
        variant: 'p',
        fontSize: '22px',
        flexDirection: 'column',
        color: 'primary',
        iconColor: 'primary',
      },
      titleIcon: {
        w: '27px',
        h: '27px',
      },
      description: {
        variant: 'helper',
        fontSize: '13px',
        textAlign: 'center',
        pb: 0,
      },
      attributes: {},
    },
  },
  corporate: {
    lg: {
      recommended: {
        variant: 'default',
      },
      card: {
        color: 'white',
        border: 'none',
        bg: 'linear-gradient(180deg, black 0%, #454545 50%, black 100%)',
        shadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
        p: '20px',
      },
      title: {
        variant: 'h2',
        color: 'white',
        iconColor: 'white',
      },
      titleIcon: {
        w: '30px',
        h: '30px',
      },
      description: {
        pb: 30,
      },
      attributes: {
        iconColor: 'warning',
        color: 'warning',
      },
    },
    sm: {
      recommended: {
        variant: 'default',
      },
      card: {
        color: 'white',
        border: 'none',
        bg: 'linear-gradient(180deg, black 0%, #454545 50%, black 100%)',
        shadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
        p: '20px',
      },
      title: {
        variant: 'p',
        fontSize: '22px',
        flexDirection: 'column',
        color: 'white',
        iconColor: 'white',
      },
      titleIcon: {
        w: '27px',
        h: '27px',
      },
      description: {
        variant: 'helper',
        fontSize: '13px',
        textAlign: 'center',
        pb: 0,
      },
      attributes: {},
    },
  },
  wsapp: {
    lg: {
      recommended: {
        variant: 'wsapp',
      },
      card: {
        border: '2px solid transparent',
        bg: 'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #00f260, #79bbff) border-box',
        shadow: '0px 2px 6px 0px #00000029',
        p: '36px 18px 25px',
      },
      title: {
        variant: 'h2',
        fontSize: '22px',
        color: '#25D366',
        iconColor: '#25D366',
        pb: '10px',
      },
      titleIcon: {
        w: '26px',
        h: '26px',
      },
      description: {
        textAlign: 'center',
        pb: 0,
      },
      attributes: {
        iconColor: 'primary',
      },
    },
    sm: {
      recommended: {
        variant: 'wsapp',
      },
      card: {
        border: '2px solid transparent',
        bg: 'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #00f260, #79bbff) border-box',
        shadow: '0px 2px 6px 0px #00000029',
        p: '20px',
      },
      title: {
        variant: 'p',
        fontSize: '18px',
        marginX: '-10px',
        color: '#25D366',
        iconColor: '#25D366',
        flexDirection: 'column',
        pb: '5px',
      },
      titleIcon: {
        w: '27px',
        h: '27px',
      },
      description: {
        fontSize: '13px',
        textAlign: 'center',
        pb: 0,
      },
      attributes: {},
    },
  },
};
