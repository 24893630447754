import { PlanCardVariantName } from '@/components/ui/planCard/planCard.types';

export const getVariant = (title: string): PlanCardVariantName => {
  const variants: { [key: string]: PlanCardVariantName } = {
    Premium: 'recommended',
    Corporate: 'corporate',
    PremiumWhatsApp: 'wsapp',
  };

  return variants[title] || 'default';
};
