import { Card } from '@chakra-ui/react';
import { Text } from 'cliengify';

import { RecommendedCardProps } from './recommendedCard.types';

const sizeProps = {
  sm: {
    w: '156px',
    top: '-6.5%',
    right: '10%',
  },
  lg: {
    w: '210px',
    top: '-2.5%',
    right: '14%',
  },
};

export const RecommendedCard = ({
  label,
  variant,
  size = 'lg',
}: RecommendedCardProps) => {
  return (
    <Card
      pt={'5px'}
      position="absolute"
      color="white"
      alignItems="center"
      background={
        variant === 'default'
          ? 'linear-gradient(180deg, #7D60F5 0%, #C059FF 100%)'
          : 'linear-gradient(180deg, #00F260 0%, #79BBFF 100%)'
      }
      zIndex={1}
      {...sizeProps[size]}
    >
      <Text variant="p" as={'b'} leftIcon="icon-trophy-win">
        {label}
      </Text>
    </Card>
  );
};
