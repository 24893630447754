export enum eventAction {
  general = 'general',
  channel = 'billing_channel',
  period = 'billing_period',
  compare = 'plan_details',
  call = 'schedule_call',
  channels = 'more_about_channels',
  chatbot = 'more_about_chatbot',
  live = 'more_about_live',
  crm = 'more_about_crm',
  integration = 'more_about_integrations',
  support = 'more_about_support',
  cta_footer = 'cta_begin_footer',
}
