import { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

const Questions = lazy(() => import('./questions.components'));

export default function QuestionsLazy() {
  const { ref, inView } = useInView();

  return (
    <div ref={ref}>
      {inView ? (
        <Suspense fallback={null}>
          <Questions />
        </Suspense>
      ) : null}
    </div>
  );
}
