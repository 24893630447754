import { Heading, Stack, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';

import * as text from '@/i18n/es';

const Hero = () => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const texts = text.default;

  return (
    <Stack
      textAlign={'center'}
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
    >
      <Heading
        as="h1"
        fontWeight={'bold'}
        fontSize={{ base: '2xl', sm: '4xl', md: '5xl' }}
        lineHeight={'110%'}
        color={'primary'}
        data-testid={'main_title'}
      >
        {parse(texts.hero.title)}
      </Heading>
      <Text
        as="h2"
        color={'gray.800'}
        maxW={'4xl'}
        fontSize={{ sm: 'xl', md: '2xl' }}
      >
        {parse(texts.hero.description)}
      </Text>
      <Stack spacing={6} direction={'row'}></Stack>
    </Stack>
  );
};

export default Hero;
