import { SyntheticEvent, useState } from 'react';
import {
  Flex,
  Spacer,
  IconButton,
  Link,
  useMediaQuery,
  Image,
  MenuItem,
  MenuList,
  Container,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import { Button, Icon, IconName } from 'cliengify';

import { NavProps } from './nav.types';

import cliengoLogo from '@/assets/nav/cliengo.webp';
import { trackEvent } from '@/utils/analytics';
import { categoryEvent } from '@/utils/categoryEvents';
import * as text from '@/i18n/es';
import { redirect } from '@/utils/redirect';

const Nav = ({ onOpen, menuRef }: NavProps) => {
  const [scroll, setScroll] = useState<boolean>(false);
  const [isLargerThan48] = useMediaQuery('(min-width: 940px)');
  const loginUrl = process.env.REACT_APP_LOGIN_LINK;
  const startUrl = process.env.REACT_APP_START_LINK;
  const texts = text.default;
  const changeScroll = () =>
    document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
      ? setScroll(true)
      : setScroll(false);

  window.addEventListener('scroll', changeScroll);

  const goToCta = (event: SyntheticEvent) => {
    event.preventDefault();

    const button = event.target as HTMLButtonElement;

    if (button) {
      let action = 'singup_home';

      if (
        button.attributes.getNamedItem('data-action')?.value?.includes('login')
      ) {
        action = 'login_home';
      }

      trackEvent(action, categoryEvent.home);
      redirect(button);
    }
  };

  return (
    <Flex
      data-testid="navbar"
      h="10vh"
      alignItems="center"
      p="6"
      boxShadow={scroll ? 'base' : 'none'}
      position="sticky"
      top="0"
      zIndex="banner"
      w="full"
      bg={scroll ? 'white' : 'transparent'}
    >
      <a href="https://cliengo.com">
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Image src={cliengoLogo} alt="Cliengo logo" />
      </a>
      {isLargerThan48 ? (
        <Container fontSize={'14px'} px={0}>
          <Menu>
            <MenuButton color={'primary'} px={4} borderRight={'2px solid'}>
              {texts.footer.products}
              <Icon
                pl={'5px'}
                fontSize={'14px'}
                name={'icon-cdown' as IconName}
              />
            </MenuButton>
            <MenuList>
              <MenuItem as="a" href={'https://www.cliengo.com/chatbot'}>
                {texts.footer.chatbotWeb}
              </MenuItem>

              <MenuItem
                as="a"
                href={'https://www.cliengo.com/chatbot-whatsapp'}
              >
                {texts.footer.chatbotWhatsapp}
              </MenuItem>

              <MenuItem
                as="a"
                href={'https://www.cliengo.com/chatbot-facebook'}
              >
                {texts.footer.chatbotFb}
              </MenuItem>
              <MenuItem
                as="a"
                href={'https://www.cliengo.com/chatbot-instagram'}
              >
                {texts.footer.chatbotIg}
              </MenuItem>
              <MenuItem as="a" href={'https://www.cliengo.com/live-chat'}>
                {texts.footer.live}
              </MenuItem>
              <MenuItem as="a" href={'https://www.cliengo.com/crm'}>
                {texts.footer.crm}
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <Link
              color={'primary'}
              px={4}
              borderRight={'2px solid'}
              href={'https://partners.cliengo.com/'}
            >
              {texts.footer.partners}
            </Link>
          </Menu>
          <Menu>
            <MenuButton color={'primary'} px={4} borderRight={'2px solid'}>
              {texts.footer.about}
              <Icon
                pl={'5px'}
                fontSize={'14px'}
                name={'icon-cdown' as IconName}
              />
            </MenuButton>
            <MenuList>
              <MenuItem as="a" href="https://www.cliengo.com/cliengo-que-es">
                {texts.footer.cliengo}
              </MenuItem>
              <MenuItem
                as="a"
                href="https://cliengo.zohorecruit.com/jobs/Careers"
              >
                {texts.footer.work}
              </MenuItem>
              <MenuItem as="a" href="https://blog.cliengo.com/casos-de-exito/">
                {texts.footer.cases}
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton color={'primary'} px={4}>
              {texts.footer.resources}
              <Icon
                pl={'5px'}
                fontSize={'14px'}
                name={'icon-cdown' as IconName}
              />
            </MenuButton>
            <MenuList>
              <MenuItem as="a" href="https://blog.cliengo.com/">
                {texts.footer.blog}
              </MenuItem>
              <MenuItem as="a" href="https://help.cliengo.com/hc/es">
                {texts.footer.help}
              </MenuItem>
              <MenuItem as="a" href="https://developers.cliengo.com/docs">
                {texts.footer.devs}
              </MenuItem>
            </MenuList>
          </Menu>
        </Container>
      ) : (
        <Spacer />
      )}

      <Flex alignItems="center">
        {isLargerThan48 ? (
          <>
            <Link
              href={loginUrl}
              onClick={goToCta}
              _hover={{ textDecoration: 'none' }}
              pr={4}
            >
              <Button
                data-action={'login'}
                data-url={loginUrl}
                fontWeight={'bold'}
                variant={'primary'}
                mode={'outline'}
                name="Login button"
              >
                {texts.nav.login}
              </Button>
            </Link>
            <Link
              href={startUrl}
              onClick={goToCta}
              _hover={{ textDecoration: 'none' }}
              pr={4}
            >
              <Button
                data-action={'start'}
                data-url={startUrl}
                fontWeight={'bold'}
                variant={'primary'}
                name="Register button"
              >
                {texts.nav.start}
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Link
              href={startUrl}
              onClick={goToCta}
              _hover={{ textDecoration: 'none' }}
              pr={4}
            >
              <Button
                data-action={'start'}
                data-url={startUrl}
                fontWeight={'bold'}
                variant={'primary'}
                name="Start in cliengo"
              >
                Comenzar
              </Button>
            </Link>
            <IconButton ref={menuRef} onClick={onOpen} aria-label={'open menu'}>
              <Icon fontSize="16px" name="icon-menu" />
            </IconButton>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Nav;
