import {
  Box,
  Link,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Icon } from 'cliengify';

import { PlansProps } from './plans.types';

import { getVariant } from '@/utils/getPlanCardVariant';
import { PlanCard } from '@/components/ui/planCard/planCard.component';
import { Plan, plans } from '@/constants/plans';
import * as text from '@/i18n/es';
import { ArrowDown } from '@/components/ui/icons/arrowDown.component';
import { trackEvent } from '@/utils/analytics';
import { eventAction } from '@/utils/eventActions';
import { categoryEvent } from '@/utils/categoryEvents';
import { eventLabel } from '@/utils/eventLabels';

const Plans = ({ onPlanSelectionChange, onPeriodChange }: PlansProps) => {
  const texts = text.default;
  const [tabIndex, setTabIndex] = useState(0);
  const [showWhatsapp, setShowWhatsapp] = useState<boolean>(false);
  const [showMonthly, setShowMonthly] = useState<boolean>(true);
  const [activePlans, setActivePlans] = useState<Plan[]>(
    plans.allChannelPlans as Plan[]
  );

  const planSelectionChange = (index: number) => {
    const isWhatsappSelected = index == 1;

    setTabIndex(index);
    setShowWhatsapp(isWhatsappSelected);

    setActivePlans(
      (isWhatsappSelected
        ? plans.whatsappPlans
        : plans.allChannelPlans) as Plan[]
    );

    onPlanSelectionChange(isWhatsappSelected);

    trackEvent(
      eventAction.channel,
      categoryEvent.home,
      isWhatsappSelected ? eventLabel.whatsapp : eventLabel.all
    );
  };

  const planPricingChange = (index: number) => {
    const isAnnual = index === 1;

    setShowMonthly(!isAnnual);
    onPeriodChange(isAnnual);

    trackEvent(
      eventAction.period,
      categoryEvent.home,
      isAnnual ? eventLabel.annual : eventLabel.monthly
    );
  };

  const goToCompare = () => {
    trackEvent(eventAction.compare, categoryEvent.home);
    const element = document.getElementById('features');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      py={12}
      mt={{ base: '-175px', sm: '-170px', xl: '-150px' }}
      bgColor="transparent"
    >
      <VStack spacing={2} textAlign="center">
        <Box w="80%">
          <Tabs
            onChange={planSelectionChange}
            variant="enclosed"
            align="center"
          >
            <TabList borderColor={'gray.500'}>
              <Tab
                role="tab"
                aria-controls="conversation-channels"
                _selected={{ color: 'white', bg: 'primary' }}
                fontWeight={'600'}
                color={'gray.700'}
                borderColor={'gray.200'}
                border="1px solid"
                borderBottom="none"
                fontSize={{ base: 'md', sm: 'xl', md: '2xl' }}
              >
                {texts.plans.converChannels}
              </Tab>
              <Tab
                role="tab"
                aria-controls="whatsapp-channels"
                _selected={{ color: 'white', bg: 'primary' }}
                bg={'rgba(255,255,255,0.4)'}
                fontWeight={'600'}
                color={'wsapp'}
                borderColor={'gray.200'}
                border="1px solid"
                borderBottom="none"
                fontSize={{ base: 'md', sm: 'xl', md: '2xl' }}
              >
                <Icon
                  name="icon-whatsapp"
                  px={{ base: 0, sm: 2 }}
                  fontSize={{ base: 'sm', sm: 'xl' }}
                />{' '}
                {texts.plans.wpChannels}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <Box pt={10}>
          <Tabs
            onChange={planPricingChange}
            align="center"
            variant="soft-rounded"
            colorScheme="green"
            borderColor={'gray.700'}
            border={'1px solid'}
            borderRadius={'full'}
            p={1}
          >
            <TabList>
              <Tab
                _selected={{ color: 'white', bg: '#00896B' }}
                color={'gray.800'}
                fontSize={{ base: 'sm' }}
                minW={'125px'}
              >
                {texts.plans.monthly}
              </Tab>
              <Tab
                _selected={{ color: 'white', bg: '#00896B' }}
                color={'gray.800'}
                fontSize={{ base: 'sm' }}
                minW={'125px'}
              >
                {texts.plans.annual}
              </Tab>
            </TabList>
          </Tabs>
        </Box>
        <Box
          pt={3}
          pb={{ base: 5 }}
          color={'gray.700'}
          fontSize={{ base: 'md', sm: '2xl' }}
        >
          <Text as="b" color={'primary'} fontSize={{ base: 'sm', sm: 'xl' }}>
            {texts.plans.saveAnnually}{' '}
          </Text>
        </Box>
      </VStack>
      <Wrap
        textAlign="center"
        justify="center"
        spacing={{ base: 4, md: 8, lg: 10 }}
        py={{ base: 4, md: 12 }}
      >
        {activePlans.map((plan) => (
          <PlanCard
            key={plan.title}
            isMonthly={showMonthly}
            variant={getVariant(plan.title.replaceAll(/\s/g, ''))}
            plan={plan}
            isWhatsapp={showWhatsapp}
          />
        ))}
      </Wrap>
      <Spacer />
      <Box w="full" textAlign={'center'}>
        <Text
          color={'gray.800'}
          fontSize={{ base: 'md', sm: '2xl' }}
          onClick={goToCompare}
          cursor="pointer"
          _hover={{ textDecor: 'underline' }}
        >
          <ArrowDown></ArrowDown>
          <Text as="span" px={3}>
            {texts.plans.comparePlans}
          </Text>
          <ArrowDown></ArrowDown>
        </Text>
      </Box>
    </Box>
  );
};

export default Plans;
