import React, { SyntheticEvent } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link,
  Flex,
} from '@chakra-ui/react';
import { Button } from 'cliengify';

import { DrawerProps } from '@/components/ui/drawer/drawer.types';
import { trackEvent } from '@/utils/analytics';
import { eventAction } from '@/utils/eventActions';
import { categoryEvent } from '@/utils/categoryEvents';

const DrawerComponent = ({ isOpen, onClose, btnRef }: DrawerProps) => {
  const loginUrl = process.env.REACT_APP_LOGIN_LINK;
  const startUrl = process.env.REACT_APP_START_LINK;

  const goToCta = (event: SyntheticEvent) => {
    event.preventDefault();
    trackEvent(eventAction.cta_footer, categoryEvent.home);

    window.location = (event.target as HTMLAnchorElement).href;
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      zIndex="popover"
    >
      <DrawerOverlay />

      <DrawerContent bg={'alternative'}>
        <DrawerCloseButton color={'white'} />

        <DrawerBody>
          <Flex flexDirection="column">
            <Link
              href={loginUrl}
              onClick={goToCta}
              _hover={{ textDecoration: 'none' }}
              pt={2}
            >
              <Button
                border={'1px solid white'}
                fontWeight={'bold'}
                variant={'primary'}
                mode={'outline'}
                size={'md'}
                name="Login button"
              >
                Iniciar sesión
              </Button>
            </Link>
            <Link
              href={startUrl}
              onClick={goToCta}
              _hover={{ textDecoration: 'none' }}
              pt={2}
            >
              <Button
                border={'1px solid white'}
                fontWeight={'bold'}
                variant={'primary'}
                size={'md'}
                name="Create account"
              >
                Comenzar
              </Button>
            </Link>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComponent;
